﻿@narrow-font: "Arial Narrow", Arial, serif;
@regular-font: Arial, serif; 
@required-color: red;
@link-blue: #337ab7;
@product-table-border: 1px solid #235D7E;

@bootstrap-xs-max-size: 767px; 
@bootstrap-sm-min-size: 768px;
@bootstrap-sm-max-size: 991px;
@bootstrap-md-min-size: 992px;
