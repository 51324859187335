﻿@import "modules/variables.less";
.home-page-feature {
    text-align: center;
    position: relative;
    margin-bottom: 20px;
    font-family: @narrow-font;

    h2 {
        font-family: @narrow-font;
        color: #236990;
        text-align: center;
        font-size: 28px;
        font-weight: 400;
        margin-bottom: 0;
    }

    p {
        font-family: @regular-font;
        text-align: center;
        color: #585858;
        font-size: 14px;
        line-height: 19px;
        padding: 10px 15px 40px 15px;
    }

    .btn {
        width: 140px;
        position: absolute;
        bottom: 0px;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        padding: 9px 8px;
        font-weight: bold;
    }
}

#home-landing {
    height: 350px;
    background-size: cover;

    h1 {
        margin-top: 60px;
        padding-left: 20px;
        color: #eabe00;
        font-family: @narrow-font;
        max-width: 390px;
        font-size: 34px;
    }

    p {
        padding-left: 20px;
        color: white;
        font-family: @regular-font;
        font-size: 20px;
        max-width: 390px;
    }

    @media(max-width: 992px) {
        padding-top: 60px;

        h1 {
            margin-top: 0;
        }
    }
}

.home-page-feature {
    text-align: center;
    position: relative;
    margin-bottom: 20px;
    font-family: @narrow-font;

    h2 {
        font-family: @narrow-font;
        color: #236990;
        text-align: center;
        font-size: 28px;
        font-weight: 400;
        margin-bottom: 0;
    }

    p {
        font-family: @regular-font;
        text-align: center;
        color: #585858;
        font-size: 14px;
        line-height: 19px;
        padding: 10px 15px 40px 15px;
    }

    .btn {
        position: absolute;
        bottom: 0px;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        padding: 9px 8px;
        font-weight: bold;
    }

    @media(max-width: 767px) {
        p {
            padding: 10px 15px 0 15px;
        }

        .btn {
            position: relative;
            left: auto;
            right: auto;
        }
    }
}

.home-page-feature-icon {
    color: #c0c0c0;
    font-size: 28px;

    span {
        display: inline-block;
        vertical-align: middle;
    }

    .outer-line {
        width: 40%;
        border-bottom: 5px solid #c0c0c0;
    }
}

.btn-home-blue {
    background: #2f7bb8;
    background-image: none;
    color: white;
}

#home-talk-to-tech {
    p {
        color: white;
        font-size: 16px;

        a {
            color: #eabe00;
            text-decoration: underline;
        }
    }

    @media(max-width: 992px) {
        padding-bottom: 20px;
    }
}

.btn-home-white {
    background-color: white;
    background-image: none;
    color: #236990;
    font-weight: bold;
    padding: 10px 12px;

    &:hover,
    &:active,
    &:focus {
        color: #236990;
    }
}

#home-technical {
    padding-bottom: 10px;
    font-family: @narrow-font;

    h2 {
        color: #236990;
        text-align: center;
        font-size: 28px;
        font-weight: 400;
        margin-bottom: 0;
    }

    p {
        font-family: @regular-font;
        text-align: center;
        color: #585858;
        font-size: 14px;
        line-height: 17px;
        padding: 10px 15px 10px 15px;
    }
}

#home-latest-datasheets {
    h3 {
        color: white;
        font-family: @narrow-font;
        font-size: 28px;
        margin: 40px 0;
        text-align: right;

        @media(max-width: 992px) {
            text-align: center;
            margin: 10px 0;
        }
    }

    #newDatasheetsContainer {
        padding-top: 20px;
        padding-right: 40px;

        .slick-prev:before, .slick-next:before {
            font-size: 40px !important;
        }

        .slick-next {
            right: 14px;
        }

        @media(max-width: 767px) {
            padding-top: 0;
            padding-right: 0;

            .slick-prev, .slick-next {
                top: 45%;

                &:before {
                    font-size: 25px !important;
                }
            }

            .slick-next {
                right: -10px;
            }
        }
    }

    .new-datasheet {
        width: 20%;
        display: inline-block;
        margin-right: 10px;
        height: 150px;
        background-color: #0e2c4c;
        color: #eabe00;

        .new-datasheet-date {
            text-align: center;
            height: 20px;
            line-height: 20px;
            font-size: 14px;
        }

        .new-datasheet-name {
            width: 90%;
            padding-left: 5px;
            padding-top: 3px;
            font-weight: bold;
            font-size: 14px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .new-datasheet-manufacturer {
            padding-left: 5px;

            img {
                max-height: 20px;
            }
        }

        .new-datasheet-image {
            color: black;
            background-image: url('/Content/Images/NewDatasheet.jpg');
            background-repeat: no-repeat;
            background-size: cover;
            height: 130px;
        }

        &:last-child {
            margin-right: 0;
        }
    }

    .slick-prev {
        display: none !important;
    }
}

#desktopHive {
    picture {
        width: 728px;
        height: 90px;
    }
}

#home-video-column {
    box-shadow: inset 0px -24px 10px -20px rgb(100, 100, 100, .5);
}

#home-articles-social {
    h3 {
        color: #236990;
        font-family: @narrow-font;
        border-bottom: 1px solid #236990;
        padding-bottom: 5px;
        margin-bottom: 15px;
    }

    .article {
        padding-bottom: 10px;

        h4 {
            color: #585858;
            font-family: @regular-font;
            font-weight: normal;
            font-size: 16px;

            display: -webkit-box;
            -webkit-line-clamp: 3;
            text-overflow: ellipsis;
            overflow: hidden;
            -webkit-box-orient: vertical;
        }

        .article-text-link {
            text-decoration: underline;
            color: #236990;
            font-family: @regular-font;
        }

        img {
            border: 2px solid #236990;
            width: 90px;
            height: 90px;
            max-width: none;
        }
    }
}

#home-articles-social #home-social a, 
#datasheet-articles #home-social a {
    font-size: 20px;
    margin: 10px 0;
    display: block;
    color: #236990;
}

.webp {
    #home-landing {
        background-image: url("/dist/Content/Images/landing.webp");
    }
}

.no-webp {
    #home-landing {
        background-image: url("/Content/Images/landing.jpg");
    }
}

@media(max-width: 1000px) {
    #desktopHive picture {
        width: 320px;
        height: 100px;
    }
}

